$ = jQuery;
var mapModule = {
  init : function() {
    if($('#map').length){
      var location = {lat: $('#map').data('lat'), lng: $('#map').data('lng')};
      var map = new google.maps.Map(
        document.getElementById('map'), {zoom: 14, center: location});
      // The marker, positioned at Uluru
      var marker = new google.maps.Marker({position: location, map: map});

    }
  }
};


var headerEvent = {
  init:function () {
    $(window).scroll(function () {
      if ($(this).scrollTop()  <= 0 ){
        $('body').removeClass('minimised');
      }else{
        $('body').addClass('minimised');
      }
    });
  }
};

var contactModule = {
  init:function () {

  }
};

var ctaBannerModule  = {
  init:function () {

  }
};

var mainSingleBannerModule  = {
  init:function () {

  }
};

var ourVideoModule  = {
  init:function () {
    $('#videoModel').on('shown.bs.modal', function () {
      $('#videoModel video').get(0).play();
    });
    $('#videoModel').on('hidden.bs.modal', function () {
      $('#videoModel video').get(0).pause();
    });
  }
};

var imageAndTextModule  = {
  init:function () {

    $('.image_and_text_slider').slick({
      arrows : false,
      dots:true,
      mobileFirst:true,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  }
};

var smallBannerModule  = {
  init:function () {

  }
};

var testimonialsModule = {
  init:function(){
    $(document).ready(function() {
      $('a', '.modalID').click(function () {
        $.getJSON('/wp-json/wp/v2/posts?include='+ $(this).parents('.modalID').data('id'), function (result) {
          $('#testimonial-content').html(result[0].content['rendered']);
        });
      });
    });
  }
}

var ecomModule  = {
  init:function () {
    
    $('.ecommerce_slider').slick({
      arrows : false,
      dots:true,
      mobileFirst:true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ]
    });
  }
};

var traineeModule  = {
  init:function () {


    $('.trainee_carousel').slick({
      arrows : false,
      dots:true,
      mobileFirst:true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        ]
    });
  }
};

var mobileMenu  = {
  init:function () {
    $('#mobileMenuBtn').click(function(){
      $(this).find('button').toggleClass('is-active');
      $('#mobileMenu').toggleClass('active');
    });
  }
};
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          headerEvent.init();
          mobileMenu.init();
          ourVideoModule.init();
          mapModule.init();
          traineeModule.init();
          ecomModule.init();
          imageAndTextModule.init();
          testimonialsModule.init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
